<template>
  <section>
    <!-- title -->
    <h5 class="text-h5 secondary--text mb-8">
      Administración de fondos de baja cuantía
    </h5>

    <!-- body -->
    <v-row class="mb-4">
      <v-col cols="12" md="5">
        <v-autocomplete
          label="Fondos asignados *"
          :items="ctl_fondos_var"
          item-text="nombre"
          item-value="id"
          v-model="id_fondo_selected_var"
          outlined
          @change="FetchListRegsByFondoFtn"
          clearable
          hide-details
        />
      </v-col>
      <v-col cols="4" md="2">
        <v-btn
          block
          :disabled="!id_fondo_selected_var"
          class="text-no-style"
          color="secondary"
          @click="show_modal_add_var = true"
          v-show="!this.diferentUser"
        >
          Añadir
        </v-btn>
      </v-col>
      <v-col cols="4" md="2">
        <v-btn
          block
          outlined
          :disabled="!id_fondo_selected_var"
          class="text-no-style"
          color="secondary"
          @click="show_upload_excel_file = true"
          v-show="!this.diferentUser"
        >
          <v-icon color="secondary">mdi-file-excel</v-icon> Cargar desde excel
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="mb-4" />

    <v-row class="justify-center" v-if="fondo_var">
      <v-col cols="12" sm="4" lg="2" class="text-center">
        <p class="m-0">Total asignado:</p>
        <h6 class="text-h6">
          $
          {{
            Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(fondo_var?.monto_establecido)
          }}
        </h6>
      </v-col>
      <v-col cols="12" sm="4" lg="2" class="text-center">
        <p class="m-0">Total disponible:</p>
        <h6 class="text-h6">
          $
          {{
            Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(fondo_var?.monto_disponible)
          }}
        </h6>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" sm="3" lg="2" v-if="haveRoles(['ROLE_AFC'])">
        <v-btn
          block
          class="text-no-style"
          color="secondary"
          outlined
          @click="confirmSendNotification = true"
        >
          <v-icon color="secondary">mdi-email</v-icon> Notificar a UCP
        </v-btn>
      </v-col>
      <v-col cols="12" sm="3" lg="2">
        <v-btn
          block
          class="text-no-style"
          color="secondary"
          outlined
          :loading="loadingReporteGeneral"
          @click="downloadReporteGeneral"
        >
          <v-icon color="secondary">mdi-download</v-icon> Reporte general
        </v-btn>
      </v-col>

      <v-col cols="12" sm="3" lg="2" v-show="id_fondo_selected_var">
        <v-btn
          block
          class="text-no-style"
          color="secondary"
          outlined
          :loading="loadingReporteFondo"
          @click="downloadReporteFondo"
        >
          <v-icon color="secondary">mdi-download</v-icon> Reporte de fondo
        </v-btn>
      </v-col>
    </v-row>

    <template v-if="id_fondo_selected_var">
      <div class="mt-8 d-flex align-center" style="gap: 16px">
        <v-text-field
          v-model="observacion"
          :loading="cargandoRegistros"
          label="Observación"
          class="flex-grow-1 flex-shrink-1 flex-lg-grow-0"
          style="flex-basis: 400px"
          placeholder="Buscar por observación"
          outlined
          clearable
          hide-details
        />
        <v-select
          v-model="estado"
          :loading="catalogoEstados.isLoading || cargandoRegistros"
          :items="catalogoEstados.data"
          clearable
          item-text="nombre"
          item-value="id"
          class="flex-grow-1 flex-shrink-1 flex-lg-grow-0"
          style="flex-basis: 400px"
          hide-details
          outlined
          label="Estado"
          placeholder="Estado"
          allow-clear
        />
        <v-btn
          @click.stop="buscarFondos"
          x-large
          color="primary"
          :loading="cargandoRegistros"
        >
          Buscar
        </v-btn>
      </div>

      <data-table-component
        :headers="HEADERS_CONST"
        :items="list_regs_var"
        :show_actions="true"
        :total_registros="total_rows_var"
        @paginar="PaginateFtn"
        v-models:pagina="pagination_var.page"
        v-models:select="pagination_var.per_page"
        class="admin-fondos-baja-cuantia-table mt-4"
      >
        <!-- Slot de mes -->
        <template v-slot:[`item.mes_aplicacion`]="{ item }">
          {{ item.mes_aplicacion | moment("MMMM") }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | moment("DD/MM/YYYY") }}
        </template>
        <template v-slot:[`item.observacion`]="{ item }">
          <span style="min-width: 225px" class="d-inline-block">{{
            item.observacion
          }}</span>
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <v-chip label>
            {{ item.estadoFondos.nombre }}
          </v-chip>
        </template>
        <template v-slot:[`item.monto_retenido`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.monto_retenido)
          }}
        </template>
        <template v-slot:[`item.monto`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.monto)
          }}
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <div class="d-flex align-center justify-center px-2" style="gap: 8px">
            <DataTableButton
              icon="mdi-eye"
              icon-color="secondary"
              tooltipText="Ver detalles"
              tooltipColor="blueGrayMinsal"
              customClass="mr-0"
              @click="OpenModalDetailFtn(item)"
            />
            <DataTableButton
              v-if="item.justificacion && item.estadoFondos.id !== 1"
              icon="mdi-information-outline"
              icon-color="secondary"
              tooltipText="Ver justificación de observación"
              tooltipColor="blueGrayMinsal"
              customClass="mr-0"
              @click="abrirModalVerJustificacion(item)"
            />
            <template v-if="!diferentUser && item.estadoFondos.id !== 1">
              <DataTableButton
                icon="mdi-pencil"
                icon-color="secondary"
                tooltipText="Editar registro"
                tooltipColor="blueGrayMinsal"
                customClass="mr-0"
                @click="showEditEvent(item)"
              />
              <DataTableButton
                icon="mdi-delete"
                icon-color="secondary"
                tooltipText="Eliminar registro"
                tooltipColor="blueGrayMinsal"
                customClass="mr-0"
                @click="showDeleteEvent(item)"
              />
            </template>
          </div>
        </template>
      </data-table-component>
    </template>

    <!-- modal agregar -->
    <v-dialog v-model="show_modal_add_var" persistent max-width="800">
      <v-card>
        <div class="headerIcon d-flex justify-end">
          <v-icon class="secondary--text" @click="CloseModalAddFtn">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <!-- registrar fondo -->
          <v-row class="mx-sm-6">
            <v-col cols="12">
              <span class="text-h6 secondary--text">
                Registro de gastos de baja cuantía
              </span>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="show_date_picker_var"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="month_report_var | moment('DD-MM-YYYY')"
                    label="Fecha *"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @blur="$v.month_report_var.$touch"
                    :error-messages="monthReportError"
                  />
                </template>
                <v-date-picker
                  v-model="month_report_var"
                  type="date"
                  color="secondary"
                  locale="es"
                  :min="minimumMonthToReport"
                  :max="moment().format('YYYY-MM-DD')"
                />
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Número de factura, CCF o ticket *"
                v-model="num_doc_var"
                outlined
                @blur="$v.num_doc_var.$touch"
                :error-messages="numDocError"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Nombre de proveedor *"
                v-model="name_prov_var"
                outlined
                @blur="$v.name_prov_var.$touch"
                :error-messages="nameProvError"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Descripción *"
                v-model="observacion_var"
                outlined
                rows="4"
                auto-grow
                clearable
                @blur="$v.observacion_var.$touch"
                @input="$v.observacion_var.$touch"
                :error-messages="observacionError"
                maxlength="250"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                label="Área solicitante *"
                :items="ctl_unidades"
                item-value="id"
                item-text="nombre"
                v-model="area_solic_var"
                outlined
                @blur="$v.area_solic_var.$touch"
                :error-messages="areaSolicError"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6">
              <money-text-field-component
                label="Monto retenido *"
                v-model="monto_retenido_var"
                ref="monto_retenido_var"
                @blur="$v.monto_retenido_var.$touch"
                :error-messages="montoRetenidoError"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <money-text-field-component
                label="Monto total *"
                v-model="monto_total_var"
                ref="monto_total_var"
                @blur="$v.monto_total_var.$touch"
                :error-messages="montoTotalError"
              />
            </v-col>
          </v-row>
          <!-- acciones -->
          <v-row cols="12" class="d-flex flex-column flex-sm-row mx-sm-4 mb-6">
            <v-btn
              class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
              min-width="30%"
              color="secondary"
              outlined
              @click="CloseModalAddFtn"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
              min-width="30%"
              color="secondary"
              @click="createRegsFondo"
            >
              Registrar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- modal detalle -->
    <v-dialog v-model="show_modal_detail_var" persistent max-width="800">
      <v-card>
        <div class="headerIcon">
          <v-icon class="secondary--text" @click="CloseModalDetailFtn">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <!-- registrar fondo -->
          <v-row class="mx-sm-6">
            <v-col cols="12">
              <span class="text-h6 secondary--text">Detalle de fondo</span>
            </v-col>
            <v-col cols="12" class="d-flex flex-column">
              <span v-text="'Fondo asignado'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.FondosBajaCuantium?.nombre"
              />
              <span v-text="'Fecha de documento'" />
              <span class="text-h6 secondary--text mb-3">
                {{ moment(detail_regs_var?.fecha).format("DD/MM/YYYY") }}
              </span>
              <span v-text="'Mes reportado'" />
              <span class="text-h6 secondary--text mb-3">
                {{ moment(detail_regs_var?.mes_aplicacion).format("MMMM") }}
              </span>
              <span v-text="'Número de documento'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.numero_documento"
              />
              <span v-text="'Proveedor'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.nombre_proveedor"
              />
              <span v-text="'Descripción'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.observacion"
              />
              <span v-text="'Área solicitante'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.area_solicitante"
              />
              <span v-text="'Monto total'" />
              <span class="text-h6 secondary--text mb-3">
                {{
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(detail_regs_var?.monto)
                }}
              </span>
            </v-col>
          </v-row>
          <!-- acciones -->
          <v-row
            cols="12"
            class="d-flex flex-column flex-sm-row mx-sm-4 mt-6 mb-3"
          >
            <v-btn
              class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
              color="secondary"
              outlined
              @click="CloseModalDetailFtn"
            >
              Cerrar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- modal upload excel file -->
    <v-dialog v-model="show_upload_excel_file" persistent max-width="600">
      <v-card>
        <v-card-title>Importar datos desde excel</v-card-title>
        <v-card-text>
          <v-alert type="warning"
            >No está permitido el uso de signos como
            <b class="black--text">$ "" ' - _ + ~ * ^ {} [] #</b> dentro del
            formato de excel, así tampoco se deben agregar líneas adicionales,
            por ejemplo totales, al final de las columnas</v-alert
          >
          <v-btn
            @click="DescargarArchivo"
            text
            class="text-capitalize pa-0 mb-4"
            color="green"
            ><v-icon color="green" class="mr-2">mdi-download</v-icon> Descargar
            formato</v-btn
          >
          <p>
            Para poder importar un archivo excel, este debe tener las siguientes
            columnas a partir de la columna A1:
          </p>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td width="40%">fecha</td>
                  <td width="60%">
                    Formato día/mes/año, por ejemplo 01/01/2023. IMPORTANTE: El
                    formato de las celdas debe ser en TEXTO
                  </td>
                </tr>
                <tr>
                  <td width="40%">mes</td>
                  <td width="60%">Número 1 - 12</td>
                </tr>
                <tr>
                  <td width="40%">numero_documento</td>
                  <td width="60%">Número de factura, CF o ticket</td>
                </tr>
                <tr>
                  <td width="40%">proveedor</td>
                  <td width="60%">Nombre del proveedor</td>
                </tr>
                <tr>
                  <td width="40%">descripcion</td>
                  <td width="60%">Descripción de la compra</td>
                </tr>
                <tr>
                  <td width="40%">area_solicitante</td>
                  <td width="60%">
                    Nombre del área solicitante, igual al registrado en sistema
                  </td>
                </tr>
                <tr>
                  <td width="40%">monto_retenido</td>
                  <td width="60%">Si no aplica, entonces ingrese 0 (cero)</td>
                </tr>
                <tr>
                  <td width="40%">monto_total</td>
                  <td width="60%">Total de la compra según documento</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-file-input
            label="Archivo excel *"
            v-model="file_excel_var"
            accept=".xlsx"
            outlined
            prepend-icon=""
            placeholder="Adjuntar archivo"
            prepend-inner-icon="mdi-paperclip"
            class="mt-4"
            hide-details
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            class="text-capitalize"
            color="secondary"
            min-width="30%"
            outlined
            @click="CloseModalUploadExcelFile"
          >
            Cerrar
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="secondary"
            min-width="30%"
            solid
            @click="uploadExcelFile"
          >
            Cargar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationDialogComponent
      :show="confirmSendNotification"
      btnConfirmar="Enviar notificación"
      btnCancel="Abortar"
      :title="`¿Desea notificar a UCP que ha finalizado los registros del fondo circulante?`"
      @close="confirmSendNotification = false"
      @confirm="sendNotification"
    />

    <ConfirmationDialogComponent
      :show="confirmDeleteGastoFondo"
      btnConfirmar="Eliminar registro"
      btnCancel="Abortar"
      :title="`¿Desea eliminar el registro seleccionado?`"
      :message="`Esta acción es irreversible`"
      @close="(confirmDeleteGastoFondo = false), (registro_selected_var = null)"
      @confirm="deleteGastoFondo"
    />

    <!-- Modal para editar -->
    <EditarRegistroBajaCuantia
      :is-open="modalEdicionRegistroAbierta"
      :registro="registro_selected_var"
      :unidades="ctl_unidades"
      @on-visibility-change="manejarVisibilidadModalEdicionRegistro"
      @on-save="confirmarEdicionRegistro"
    />
    <ConfirmationModalComponent
      :is-open="modalConfirmacionEdicionAbierta"
      description="¿Desea continuar con la edición de este registro?"
      :is-loading="edicionCargando"
      @cancel="modalConfirmacionEdicionAbierta = false"
      @confirm="manejarEdicionRegistro"
    />
    <!-- ver observacion -->
    <VisualizarObservacion
      :is-open="modalVerJustificacionAbierta"
      :motivo="justificacionFondoSeleccionado"
      @on-visibility-change="manejarVisibilidadModalVerJustificacion"
    />
  </section>
</template>

<script>
// importacion de librerias
import { required, helpers } from "vuelidate/lib/validators";
import EditarRegistroBajaCuantia from "./components/EditarRegistroBajaCuantia.vue";
import moment from "moment";
import { mapState } from "vuex";
import excel4node from "excel4node";

// importacion de componentes globales
import DataTableComponent from "../../components/DataTableComponent.vue";
import DataTableButton from "../../components/DataTableButton.vue";
import MoneyTextFieldComponent from "../../components/MoneyTextFieldComponent.vue";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";
import VisualizarObservacion from "./components/VisualizarObservacion.vue";
import { ConfirmationModalComponent } from "@/components/utils";
import {
  createLoadable,
  toggleLoadable,
  setLoadableResponse,
} from "@/utils/loadable";

const txtArea = helpers.regex(
  "txtArea",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);

export default {
  components: {
    DataTableComponent,
    DataTableButton,
    MoneyTextFieldComponent,
    ConfirmationDialogComponent,
    EditarRegistroBajaCuantia,
    ConfirmationModalComponent,
    VisualizarObservacion,
  },

  validations: {
    month_report_var: {
      required,
    },
    num_doc_var: {
      required,
    },
    name_prov_var: {
      required,
    },
    observacion_var: {
      required,
      txtArea,
    },
    area_solic_var: {
      required,
    },
    monto_retenido_var: {
      required,
    },
    monto_total_var: {
      required,
    },
  },

  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        align: "center",
        text: "Correlativo",
        value: "correlativo",
      },
      {
        align: "left",
        text: "Fecha de documento",
        value: "fecha",
      },
      {
        align: "left",
        text: "Mes a reportar",
        value: "mes_aplicacion",
      },
      {
        align: "left",
        text: "Proveedor",
        value: "nombre_proveedor",
      },
      {
        align: "left",
        text: "Número documento",
        value: "numero_documento",
      },
      {
        align: "left",
        text: "Observación",
        value: "observacion",
      },
      {
        align: "end",
        text: "Monto retenido ($)",
        value: "monto_retenido",
      },
      {
        align: "end",
        text: "Monto total ($)",
        value: "monto",
      },
      {
        align: "center",
        text: "Estado",
        value: "estado",
      },
      {
        align: "center",
        text: "Acciones",
        value: "acciones",
        sortable: false,
      },
    ],

    // variables
    show_date_picker_var: false,
    show_modal_add_var: false,

    month_report_var: null,
    num_doc_var: null,
    name_prov_var: null,
    observacion_var: null,
    area_solic_var: null,
    monto_retenido_var: null,
    monto_total_var: null,

    show_modal_detail_var: false,
    id_regs_selected_var: null,
    detail_regs_var: {},

    ctl_fondos_var: [],
    id_fondo_selected_var: null,
    list_regs_var: [],
    pagination_var: {
      page: 1,
      per_page: 10,
    },
    total_rows_var: 0,
    loadingReporteGeneral: false,
    loadingReporteFondo: false,
    loadingSendUCP: false,
    confirmSendNotification: false,
    fondo_var: null,
    ctl_unidades: [],
    confirmDeleteGastoFondo: false,
    registro_selected_var: null,
    diferentUser: null,

    // variables para el modal de carga de excel
    show_upload_excel_file: false,
    file_excel_var: null,
    minDateToReport: null,

    // variables para editar un registro
    modalEdicionRegistroAbierta: false,
    modalConfirmacionEdicionAbierta: false,
    edicionData: null,
    edicionCargando: false,
    modalVerJustificacionAbierta: false,

    cargandoRegistros: false,
    // catalogo de estados
    catalogoEstados: createLoadable([]),
    observacion: "",
    estado: null,
  }),

  computed: {
    ...mapState(["selectedUnidad", "userInfo"]),

    monthReportError() {
      const errors = [];
      if (!this.$v.month_report_var.$dirty) return errors;
      !this.$v.month_report_var.required &&
        errors.push("El mes a reportar es requerido");
      return errors;
    },

    numDocError() {
      const errors = [];
      if (!this.$v.num_doc_var.$dirty) return errors;
      !this.$v.num_doc_var.required &&
        errors.push("El número de documento es requerido");
      return errors;
    },

    nameProvError() {
      const errors = [];
      if (!this.$v.name_prov_var.$dirty) return errors;
      !this.$v.name_prov_var.required &&
        errors.push("El nombre del proveedor es requerido");
      return errors;
    },

    observacionError() {
      const errors = [];
      if (!this.$v.observacion_var.$dirty) return errors;
      !this.$v.observacion_var.required &&
        errors.push("La descripción es requerida");
      !this.$v.observacion_var.txtArea &&
        errors.push("El motivo posee caracteres especiales no permitidos");
      return errors;
    },

    areaSolicError() {
      const errors = [];
      if (!this.$v.area_solic_var.$dirty) return errors;
      !this.$v.area_solic_var.required &&
        errors.push("El área solicitante es requerida");
      return errors;
    },

    montoRetenidoError() {
      const errors = [];
      if (!this.$v.monto_retenido_var.$dirty) return errors;
      !this.$v.monto_retenido_var.required &&
        errors.push("El monto retenido es requerido");
      return errors;
    },

    montoTotalError() {
      const errors = [];
      if (!this.$v.monto_total_var.$dirty) return errors;
      !this.$v.monto_total_var.required &&
        errors.push("El monto total es requerido");
      return errors;
    },

    minimumMonthToReport() {
      return this.minDateToReport;
    },

    justificacionFondoSeleccionado() {
      return this.registro_selected_var
        ? this.registro_selected_var.justificacion
        : "";
    },
  },

  methods: {
    // Funciones regulares

    /**
     *
     * @description: funcion para cerrar el modal de agregar fondo
     */
    CloseModalAddFtn() {
      this.show_modal_add_var = false;
      this.month_report_var = null;
      this.num_doc_var = null;
      this.name_prov_var = null;
      this.observacion_var = null;
      this.area_solic_var = null;

      this.$refs.monto_retenido_var.updateValue("");
      this.$refs.monto_total_var.updateValue("");

      this.$v.$reset();
    },

    /**
     *
     * @description: funcion para cerrar el modal de detalles del fondo
     */
    CloseModalDetailFtn() {
      this.show_modal_detail_var = false;
      this.id_regs_selected_var = null;
      this.detail_regs_var = {};
    },

    /**
     *
     * @description: funcion para abrir el modal de detalles del fondo
     * @param {Object} item_param
     */
    OpenModalDetailFtn(item_param) {
      this.id_regs_selected_var = item_param.id;
      this.detail_regs_var = item_param;
      this.show_modal_detail_var = true;
    },

    // Funciones asincronas

    /**
     *
     * @description: funcion para crear un registro de fondo
     */
    async createRegsFondo() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.temporalAlert({
          message: "Por favor, complete los campos requeridos",
          show: true,
          type: "warning",
        });
        return false;
      }

      const RESP_SC_CONST = await this.services.BajaCuantia.postAddRegFondo(
        this.id_fondo_selected_var,
        {
          fecha: this.month_report_var,
          numero_documento: this.num_doc_var,
          nombre_proveedor: this.name_prov_var,
          area_solicitante: this.area_solic_var,
          descripcion: this.observacion_var,
          monto_retenido: Number(this.monto_retenido_var),
          monto_total: Number(this.monto_total_var),
        }
      );

      if (RESP_SC_CONST.status === 201) {
        this.temporalAlert({
          message: "Registro del fondo creado correctamente",
          show: true,
          type: "success",
        });
        this.FetchListRegsByFondoFtn(true);
      } else {
        this.temporalAlert({
          message: "Error al crear el registro del fondo",
          show: true,
          type: "error",
        });
      }
      this.CloseModalAddFtn();
    },

    /**
     *
     * @description funcion para paginar la tabla de fondos ( API )
     * @param {Object} filters_param
     */
    async PaginateFtn(filters_param) {
      const { cantidad_por_pagina, pagina } = filters_param;

      this.pagination_var.per_page = cantidad_por_pagina;
      this.pagination_var.page = pagina;

      this.FetchListRegsByFondoFtn();
    },

    /**
     *
     * @description: funcion para obtener la lista de registros por fondo ( API )
     * @param {Boolean} init_param
     */
    async FetchListRegsByFondoFtn(init_param = false) {
      this.cargandoRegistros = true;
      if (this.id_fondo_selected_var) {
        if (init_param) {
          this.pagination_var.page = 1;
          this.pagination_var.per_page = 10;
        }

        const filters_sc_var = {
          page: this.pagination_var.page,
          per_page: this.pagination_var.per_page,
          estado: this.estado,
          observacion: this.observacion,
        };

        const RESP_SC_CONST = await this.services.BajaCuantia.getListRegsFondos(
          this.id_fondo_selected_var,
          filters_sc_var
        )
          .catch((error) => {
            this.id_fondo_selected_var = null;
            throw error;
          })
          .finally(() => {
            this.cargandoRegistros = false;
          });

        const { data, headers } = RESP_SC_CONST;
        this.list_regs_var = data.registros;
        this.fondo_var = data.fondo;
        this.diferentUser = data.diferentUser;

        this.pagination_var.page = Number(headers.page);
        this.pagination_var.per_page = Number(headers.per_page);
        this.total_rows_var = Number(headers.total_rows);

        this.list_regs_var.forEach((item, index) => {
          item.correlativo = index + 1;
        });
      } else {
        this.list_regs_var = [];
        this.pagination_var.page = 1;
        this.pagination_var.per_page = 10;
        this.total_rows_var = 0;
      }
    },

    /**
     *
     * @description: funcion para obtener la lista de fondos catalogo ( API )
     */
    async FetchCtlFondosFtn() {
      const RESP_SC_CONST = await this.services.BajaCuantia.getListFondos({
        pagination: false,
      });

      if (RESP_SC_CONST.status === 200) {
        this.ctl_fondos_var = RESP_SC_CONST.data;
      }
    },

    async downloadReporteGeneral() {
      this.loadingReporteGeneral = true;
      const { data, status } =
        await this.services.BajaCuantia.getReporteGeneralFondos();
      if (status == 200) {
        const blob = new Blob([data], {
          type: "application/octet-stream",
        });

        const file = new File(
          [blob],
          `reporte-general-fondos${this.moment().format("DD-MM-YYYY")}.xlsx`,
          {
            type: "application/octet-stream",
          }
        );

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = file.name;
        link.click();
        this.loadingReporteGeneral = false;
      }
    },
    async downloadReporteFondo() {
      this.loadingReporteFondo = true;
      const { data, status } = await this.services.BajaCuantia.getReporteFondo(
        this.id_fondo_selected_var
      );
      if (status == 200) {
        const blob = new Blob([data], {
          type: "application/octet-stream",
        });

        const file = new File(
          [blob],
          `reporte-fondo${this.moment().format("DD-MM-YYYY")}.xlsx`,
          {
            type: "application/octet-stream",
          }
        );

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = file.name;
        link.click();
        this.loadingReporteFondo = false;
      }
    },
    async sendNotification() {
      const { status } =
        await this.services.BajaCuantia.enviarNotificacionUCP();

      if (status == 200) {
        this.temporalAlert({
          message: "Notificación enviada a UCP con éxito",
          show: true,
          type: "success",
        });
      }
      this.confirmSendNotification = false;
    },
    async getUnidades() {
      const response = await this.services.UnidadesServices.getAllUnidades({
        id_institucion: this.selectedUnidad.instituciones.id,
      });
      this.ctl_unidades = response.data;
    },
    async deleteGastoFondo() {
      const { status } = await this.services.BajaCuantia.deleteGastoFondo(
        this.registro_selected_var.id
      );
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: "Registro eliminado con éxito",
          type: "success",
        });
        this.FetchListRegsByFondoFtn(true);
      }
      this.confirmDeleteGastoFondo = false;
    },
    showDeleteEvent(data) {
      this.registro_selected_var = data;
      this.confirmDeleteGastoFondo = true;
    },
    // Funciones para carga de archivo excel
    async uploadExcelFile() {
      const formData = new FormData();
      formData.append("file", this.file_excel_var);
      const { status } = await this.services.BajaCuantia.uploadExcel(
        this.id_fondo_selected_var,
        formData
      );
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: "Datos registrados con éxito",
          type: "success",
        });
        this.FetchListRegsByFondoFtn(true);
      }

      this.CloseModalUploadExcelFile();
    },
    CloseModalUploadExcelFile() {
      this.file_excel_var = null;
      this.show_upload_excel_file = false;
    },

    async DescargarArchivo() {
      const workbook = new excel4node.Workbook();
      const sheet = workbook.addWorksheet("Formato");
      const columns = [
        { columna: 1, nombre: "fecha" },
        { columna: 2, nombre: "mes" },
        { columna: 3, nombre: "numero_documento" },
        { columna: 4, nombre: "proveedor" },
        { columna: 5, nombre: "descripcion" },
        { columna: 6, nombre: "area_solicitante" },
        { columna: 7, nombre: "monto_retenido" },
        { columna: 8, nombre: "monto_total" },
      ];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < columns.length; i++) {
        sheet.cell(1, columns[i].columna).string(columns[i].nombre);
      }

      const data = await workbook.writeToBuffer();
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "formato-baja-cuantia.xlsx");
      document.body.appendChild(link);
      link.click();
    },

    async getMinDateToReport() {
      // Excepción para poder ingresar en agosto los gastos de mayo 2023, aunque no cumpla con la validación, después deberá funcionar de la manera establecida
      if (moment().format("MM") == "08" && moment().format("YYYY") == "2023") {
        this.minDateToReport = moment()
          .subtract(3, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        return;
      }

      const fechaActual = moment().startOf("month");

      const response = await this.services.BajaCuantia.postAsueto({
        fecha_inicio: fechaActual,
        dias: 4,
      });
      if (response.status == 200 || response.status == 201) {
        const fechaFinal = moment(response.data.fecha_final).format(
          "YYYY-MM-DD"
        );
        const fechaFinalMoment = moment(fechaFinal);

        if (moment().isBefore(fechaFinalMoment)) {
          this.minDateToReport = moment()
            .subtract(3, "months")
            .startOf("month")
            .format("YYYY-MM-DD");
        } else {
          this.minDateToReport = moment()
            .subtract(2, "months")
            .startOf("month")
            .format("YYYY-MM-DD");
        }
      } else {
        this.temporalAlert({
          show: true,
          message: "No se ha podido obtener la fecha límite para reportar",
          type: "error",
        });
      }
    },

    // Edicion registros
    showEditEvent(data) {
      this.registro_selected_var = { ...data };
      this.modalEdicionRegistroAbierta = true;
    },
    manejarVisibilidadModalEdicionRegistro(visible) {
      this.modalEdicionRegistroAbierta = visible;
    },
    confirmarEdicionRegistro(data) {
      this.edicionData = data;
      this.modalEdicionRegistroAbierta = false;
      this.modalConfirmacionEdicionAbierta = true;
    },
    async manejarEdicionRegistro() {
      try {
        this.edicionCargando = true;
        const idRegistro = this.registro_selected_var.id;
        const { status } = await this.services.BajaCuantia.editarGastoFondo(
          idRegistro,
          this.edicionData
        );

        if (status == 200) {
          this.pushAppMessage({
            type: "success",
            message: "Registro actualizado correctamente.",
          });
          this.FetchListRegsByFondoFtn(true);
        }
      } catch (error) {
        // this.pushAppMessage({ type: 'error', message: 'Error al actualizar el registro.' });
      } finally {
        this.modalConfirmacionEdicionAbierta = false;
        this.edicionCargando = false;
      }
    },
    manejarVisibilidadModalVerJustificacion(visible) {
      this.modalVerJustificacionAbierta = visible;
    },
    abrirModalVerJustificacion(data) {
      this.registro_selected_var = { ...data };
      this.modalVerJustificacionAbierta = true;
    },
    // Cargar catalogo de estados
    async cargarCatalogoEstados() {
      toggleLoadable(this.catalogoEstados);
      const { data } = await this.services.BajaCuantia.cargarCatalogoEstados();
      setLoadableResponse(this.catalogoEstados, data);
    },
    buscarFondos() {
      this.FetchListRegsByFondoFtn(true);
    },
  },
  created() {
    this.FetchCtlFondosFtn();
    this.getUnidades();
    this.getMinDateToReport();
    this.cargarCatalogoEstados();
  },
};
</script>

<style scoped>
.admin-fondos-baja-cuantia-table >>> table thead tr,
.admin-fondos-baja-cuantia-table >>> table tbody tr td {
  background-color: #fff !important;
}

.admin-fondos-baja-cuantia-table >>> table thead tr th:last-child,
.admin-fondos-baja-cuantia-table >>> table tbody tr td:last-child {
  position: sticky;
  right: 0;
}
</style>
